'use client'

import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

type ItemCardProps = {
  id: number
  size: string
  price: number
  text: string
  width: number
  height: number
}

const Items = [
  {
    id: 52,
    size: 's',
    text: '春のお花を散りばめて、medeluらしいふんわりと柔らかいイメージで束ねました。ぱっと気持ちが明るくなるようなイエロー系のお花とラッピングでご用意してます。',
    width: 20,
    height: 47,
    price: 3300,
  },
  {
    id: 53,
    size: 'm',
    text: '人気の淡いピンク系グラデーションの色合いでまとめた、ほんのり甘めなブーケ。満足感のあるお花のボリュームに、上品な雰囲気で仕上げました。',
    width: 40,
    height: 47,
    price: 5500,
  },
  {
    id: 54,
    size: 'l',
    text: '人気の淡いブルー系をまとめた大人シンプルなブーケ。感謝の気持ちをお花に込めて、ボリュームたっぷりでご用意しています。花の豪華さや、見たことのない花との出会いにとっても喜ばれそうです。',
    width: 60,
    height: 55,
    price: 8800,
  },
]

const ItemCard = ({ id, size, price, text, width, height }: ItemCardProps) => {
  return (
    <div className="mb-12">
      <div className="flex mb-4">
        <div className="w-1/2 pr-4">
          <Image
            src={`/images/item/gift/${size}_01.jpg`}
            width={574}
            height={383}
            alt={'ブーケ写真'}
            blurDataURL={`/images/item/gift/${size}_01.jpg`}
          />
        </div>
        <div className="w-1/2 flex flex-col justify-between">
          <div className="w-full">
            <Image
              src={`/images/item/gift/${size}_02.jpg`}
              width={574}
              height={383}
              alt={'ブーケ写真'}
              blurDataURL={`/images/item/gift/${size}_02.jpg`}
            />
          </div>
          <div className="w-full">
            <Image
              src={`/images/item/gift/${size}_03.jpg`}
              width={574}
              height={383}
              alt={'ブーケ写真'}
              blurDataURL={`/images/item/gift/${size}_03.jpg`}
            />
          </div>
        </div>
      </div>
      <p className="text-lg">ギフトブーケ（{size.toUpperCase()}）</p>
      <p className="mb-4">
        {price.toLocaleString()}
        <span className="text-xs">円（税込・送料込）</span>
      </p>
      <p className="text-sm mb-4 whitespace-pre-wrap">
        {`${text}\n※写真はイメージになります。花材の内容は仕入れ状況により変更になります。`}
      </p>
      <p className="text-sm mb-8">
        サイズ　縦:約{height}㎝ x 横:約{width}㎝
      </p>
      {/* <AddToCart variationId={id} availableForSale isWide /> */}
    </div>
  )
}

const Home = () => {
  return (
    <main className="flex min-h-screen flex-col items-center bg-zinc-100">
      <div className="w-full max-w-2xl mx-auto pt-16 bg-white">
        <Image
          src="/images/gift.png"
          width={800}
          height={400}
          alt="春のギフトブーケ"
          loading="eager"
          blurDataURL="/images/gift.png"
        />
        <div className="flex mt-2">
          <Image
            src="/images/gift01.jpeg"
            width={800}
            height={400}
            alt="春のギフトブーケ"
            className="w-1/3 p-2 pl-0"
            loading="eager"
            blurDataURL="/images/gift01.jpeg"
          />
          <Image
            src="/images/gift02.jpg"
            width={800}
            height={400}
            alt="春のギフトブーケ"
            className="w-1/3 p-2"
            loading="eager"
            blurDataURL="/images/gift02.jpeg"
          />
          <Image
            src="/images/gift03.jpg"
            width={800}
            height={400}
            alt="春のギフトブーケ"
            className="w-1/3 p-2 pr-0"
            loading="eager"
            blurDataURL="/images/gift03.jpeg"
          />
        </div>
      </div>
      <div className="w-full max-w-2xl mx-auto p-4  md:px-16 bg-white text-my-color">
        <div className="mb-8">
          <p className="mb-4 md:whitespace-pre-wrap">
            {`離任式・送別・退職・新しい門出やお祝い、あらゆるシーンに贈りたいブーケをご用意しました。\n春のお花をたっぷりと束ねてますので、贈る方のイメージに合わせてお花選びをお楽しみください。`}
          </p>
          <div className="w-full text-center md:text-left">
            <p className="text-lg font-bold text-medelu-pink mb-2 md:mb-0">
              <span className="block md:inline md:mr-2">予約期間</span>
              <span className="text-base md:text-lg line-through">
                2024年3月6日(水)～4月7日(日)
              </span>
            </p>
            <p className="text-lg font-bold mb-2">予約受付は終了しました。</p>
            <p className="text-lg font-bold text-medelu-pink">
              <span className="block md:inline md:mr-2">受取期間</span>
              <span className="text-base md:text-lg">
                2024年3月13日(水)～4月28日(日)
              </span>
            </p>
          </div>
        </div>
        {Items.map((item) => (
          <ItemCard key={item.id} {...item} />
        ))}
        <div className="text-sm whitespace-pre-wrap leading-relaxed">
          <p className="font-bold">■配送について</p>
          <ul>
            <li className="mb-2">{`・配送エリア　全国配送（配送不可地域あり）\n北海道、青森、秋田、岩手、沖縄　（その他離島も含む）は配送不可。\n※天候、災害、感染症の拡大等により、やむを得ず配送に遅延が生じる場合がございますので、予めご了承ください。`}</li>
            <li className="mb-2">・クール便不可</li>
            <li className="mb-2">・お受け取り方法　手渡しのみ</li>
            <li className="mb-2">・配送業者　日本郵便</li>
            <li className="mb-2">{`・受取日　指定日配送いたします。\nお申し込みフォームにて日時をご選択いただけます。（月・火の到着指定は不可）\nお花の鮮度を保持する関係で、お花をご利用される前日には、お受け取りいただけますようお願いします。`}</li>
            <li className="mb-2">・ラッピングしてお届けします。</li>
            <li className="mb-2">・メッセージカードはありません。</li>
            <li className="mb-2">・栄養剤がつきます。</li>
          </ul>
          <p className="font-bold">※注意事項※</p>
          <ul>
            <li className="mb-2">
              ・ご注文者様の住所とお届け先のご住所が異なる場合は、ご入力の際ご注意ください。ご注文の際に再度ご確認をお願いします。
            </li>
            <li className="mb-2">
              ・交通状況や配送状況により遅れる可能性もございますので、ご了承くださいませ。
            </li>
            <li className="mb-2">
              ・花の種類や色合いについて、仕入れ状況により変更される場合もございますので、ご了承くださいませ。
            </li>
            <li className="mb-2">
              ・到着後は、なるべく涼しいところや冷暗所での保管をお願いします。
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full max-w-2xl mx-auto p-4 md:p-16 bg-white">
        <p className="text-lg mb-2">（予約期間終了）卒園・卒業向けブーケ</p>
        <Link
          href="/graduation"
          className="hover:opacity-80 transition duration-300 ease-in-out"
        >
          <Image
            src="/images/item/ogp_arigato.jpg"
            width={800}
            height={400}
            alt="卒園・卒業向けブーケ"
            className="rounded-md"
          />
        </Link>
      </div>
    </main>
  )
}

export default Home
